<!-- App.svelte -->
<script>
  import { Router, Route } from "svelte-routing";
  import Index from "./views/Index.svelte";
  export let url = "";
  import { setContext } from 'svelte';
  let installFolder = "/"; //If installed to folder e.g "/UnturnedGuard/" if to root then it should be "/"
  setContext('installFolder', installFolder) 
</script>

<Router url="{url}">
  <Route path="{installFolder}" component="{Index}" />
</Router>
