<script>
  const featuresList = [
    {type:"demo", name:"Periodic Ban System", value:"Players are banned automatically after a specific period of time has passed since their detection by UnturnedGuard, similar to BattlEye behavior."},
    {type:"demo", name:"uPanel", value:"Built-in support for uPanel Ban System."},
    {type:"demo", name:"AdminApproval", value:"Built-in approval for Admin staff when cheaters are spotted but the anticheat not 100% sure that this is a cheater, and can be a false positive."},
    {type:"demo", name:"AntiFakeHWID", value:"Recognizes if player trying to change HWID or fake it."},
    {type:"demo", name:"AntiFarDamage", value:"Recognizes if a player is trying to damage other players far/abnormally than the allowed in Unturned."},
    {type:"demo", name:"BetterAntiClumsy", value:"Recognizes if player use Clumsy application or use fake-lag."},
    {type:"demo", name:"CheatTested", value:"All UnturnedGuard features have been tested using real cheats in Unturned."},
    {type:"demo", name:"Ping Ignore", value:"UnturnedGuard automatically ignores players that have bad connection/high ping issues, to prevent false positives."},
    {type:"demo", name:"Logging", value:"Logging of the spotted players, and abnormal player's behavior."},
    {type:"demo", name:"Micro-lag Detect", value:"In case of server micro-lags anti-cheat will stop their work for a while."},
    {type:"demo", name:"Custom Ban", value:"You can use any ban command in configuration for banning players."},
    {type:"demo", name:"OM+RM+Vanilla", value:"OpenMod, RocketMod, and Vanilla are supported."},
    {type:"demo", name:"Whitelist", value:"Modern way of whitelisting players through command, instead of outdated and laggy RocketMod/OpenMod permissions, groups/roles."},
    {type:"demo", name:"Safe Plugin Reload", value:"Feel free to reload the plugin to update the configuration or for any other reasons."},
    {type:"gold", name:"BetterAntiFakeHWID", value:"This tool is designed to recognize attempts made by players to change or fake their Hardware ID (HWID). You are welcome to use it alongside AntiFakeHWID for enhanced security."},
    {type:"gold", name:"AntiInvalidHWIDBehavior", value:"Recognizes attempts of chaning or manipulating with HWID, feel free to use a long side with any other HWID feature."},
    {type:"gold", name:"AntiInvalidKill", value:"Against players who are trying to kill other player in a way which is not possible to do without cheats."},
    {type:"gold", name:"AntiSilentAim", value:"Against players who are using silent aim."},
    {type:"gold", name:"AntiDamageThroughWalls", value:"Against players who are trying to deal damage through wall."},
    {type:"gold", name:"Discord Webhook", value:"Sends an important info about spotted/banned players into the Discord Webhook."},
    {type:"gold", name:"AntiMessageSpam", value:"Recognizes if player trying to spam messages."},
    {type:"gold", name:"AntiVulnerableOS", value:"Doesn't allow to join the server when running on vulnerable OS (Operating System)."},
    {type:"gold", name:"BetterAntiFakeSteamProfile", value:"Gives some amount of warnings to player if steam profile is new (work even with private profiles)."},
    {type:"gold", name:"FakeSteamProfileAnalyzer", value:"Recognizes: purchased acoount; twink account; boosted hours in games."},
    {type:"gold", name:"SteamProfileRestriction", value:"To reject accounts by: VAC; Creation Account Date; Friends; Hidden Profiles; block newly created accounts from joining; and many many more..."},
    {type:"gold", name:"UnturnedHourRestriction", value:"To block players with a small number of hours in Unturned."}
  ];

  let selectedType = "demo"; 
	let selectedName = "Periodic Ban System";
  
	let featuresByType = [];
	$: featuresByType = selectedType == "demo" ? featuresList.filter(f => f.type == "demo") : featuresList;

  let feature = "";
	$: feature = featuresList.find(item => item.name === selectedName)?.value;
</script>
<button
  class="{selectedType == "demo" ? 'bg-ug-green' : 'bg-ug-d3grey'} text-ug-black active:bg-ug-gold font-bold px-4 py-1 rounded-lg outline-none focus:outline-none text-lg shadow hover:shadow-lg ease-linear transition-all duration-150"
  type="button"
  on:click={()=>{selectedName = "Periodic Ban System"; selectedType="demo";}}
>
Demo
</button>
<button
  class="{selectedType == "demo" ? 'bg-ug-d3grey' : 'bg-ug-gold'} text-ug-black active:bg-ug-gold font-bold px-4 py-1 rounded-lg outline-none focus:outline-none text-lg shadow hover:shadow-lg ease-linear transition-all duration-150"
  type="button"
  on:click={()=>{selectedName = "Periodic Ban System"; selectedType="gold";}}
>
  Gold
</button>
<br>
<div class="w-full mt-5">
  <div class="mr-1 mb-1 px-4 py-5 flex-auto bg-ug-d3grey shadow-lg rounded-lg">
    {#each featuresByType as {type, name}, i}
      <button
        class="bg-ug-d4grey rounded-lg outline-none focus:outline-none px-6 py-2 m-2 hover:text-ug-green"
        type="button"
          on:click={()=>selectedName=name}
        >
          {name}
        </button>
      {/each}
    <br>
  </div>
</div>
<br>
<div class="w-full">
  <div class="px-4 py-5 flex-auto bg-ug-d3grey shadow-lg rounded-lg">
    <p class="mt-2 mb-4 text-ug-black font-bold">
      {selectedName}  
    </p>
    <p class="mt-2 mb-4 text-ug-black text-base">
      {#if feature}
        {feature}  
      {/if}
    </p>
  </div>
</div>
