<footer class="relative bg-ug-d1grey pt-8 pb-6">
  <div class="container mx-auto px-4">
    <hr class="my-6 border-blueGray-300" />
    <div class="flex flex-wrap items-center md:justify-between justify-center">
      <div class="w-full md:w-4/12 px-4 mx-auto text-center">
        <div class="text-sm text-blueGray-500 font-semibold py-1">
          Copyright © <script>document.write(new Date().getFullYear())</script> UnturnedGuard by 
          <a
            href="https://github.com/sunnamed434"
            class="lg:text-ug-black lg:hover:text-ug-green text-sm font-semibold py-1"
          >sunnamed434
          </a>
          | <br/>Site design by 
          <a
            href="https://github.com/Kvarkas"
            class="lg:text-ug-black lg:hover:text-ug-green text-sm font-semibold py-1"
          >
            Kvarkas
          </a>
        </div>
        <div class="mt-4 text-sm text-blueGray-500 font-semibold">
          This site is not affiliated with Smartly Dressed Games or Unturned. Unturned® is a registered trademark or trademark of Smartly Dressed Games Ltd. in the United States, Canada, and other countries.
        </div>
      </div>
    </div>
  </div>
</footer>