<script>
  // core components
  import Navbar from "components/Navbars/Navbar.svelte";
  import Footer from "components/Footers/Footer.svelte";
  import Features from "components/Features/Features.svelte";
  import {getContext} from 'svelte';
  let installFolder = getContext('installFolder')

  const teamMembers = [
    {
      nickname: "sunnamed434",
      logo: `${installFolder}assets/img/sunnamed.png`,
      link: "https://github.com/sunnamed434",
      description: "Founder & Dev."
    }
  ];
</script>

<div>
  <Navbar />
  <main>
    <div
      class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-65 bg-ug-d1grey"
    >
      <div class="container relative mx-auto">
        <div class="items-center flex flex-wrap justify-center">
          <div class="w-full lg:w-6/12">
            <div class="pr-10 mlr-4 mt-20">
              <h1 class="text-white font-semibold text-5xl">
                <span class="text-ug-green">Unturned</span><span class="text-ug-black">Guard</span>
              </h1>
              <p class="mt-4 mb-4 text-xl text-ug-black">
                Free and paid AntiCheat for your servers, with a many-many features against cheating!
              </p>
              
              <a 
                href="https://discord.gg/2qzn4P7qN2" 
                class="bg-ug-green text-white active:bg-ug-gold font-bold px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 uppercase text-lg shadow hover:shadow-lg ease-linear transition-all duration-150" 
                target="_blank"
              >
                Get Started
              </a>
            </div>
          </div>
          <div class="mt-8">
            <img alt="..." src="{installFolder}assets/img/UnturnedGuardLogo.png" class="shadow-xl h-auto border-none max-w-150-px">
          </div>
          <div class="mt-8">
            <img alt="..." src="{installFolder}assets/img/UnturnedGuardFreeLogo.png" class="shadow-xl h-auto border-none max-w-150-px">
          </div>
        </div>
      </div>
    </div>

    <section class="pb-20 bg-ug-d1grey -mt-24">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
            >
              <div class="px-4 py-5 flex-auto bg-ug-d3grey shadow-lg rounded-lg">
                <h6 class="text-xl font-semibold">
                  <span class="text-ug-green">Demo</span> <span class="text-ug-black">Plan</span>
                </h6>
                <p class="mt-2 mb-4 text-ug-black">
                  $0 
                  <span class="mr-2 text-sm">
                    / month
                  </span>
                  <br>
                  <a 
                    href="https://discord.gg/2qzn4P7qN2" 
                    class="bg-ug-green text-white active:bg-ug-gold font-bold px-2 py-2 rounded outline-none focus:outline-none uppercase text-lg shadow hover:shadow-lg ease-linear transition-all duration-150" 
                    target="_blank"
                  >
                    Get Started
                  </a>
                </p>
                <span class="mr-2 text-base">
                  13/32 Features
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Discord Webhook
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Moderation2 Integration
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  RocketMod, OpenMod, and Vanilla
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Logging
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Built-in approval for Admin staff
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Server micro-lags detection
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Whitelist
                </span>
              </div>
            </div>
          </div>

          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
            >
              <div class="px-4 py-5 flex-auto bg-ug-d3grey shadow-lg rounded-lg">
                <h6 class="text-xl font-semibold">
                  <span class="text-ug-green">Gold</span> <span class="text-ug-black">Plan</span>
                </h6>
                <p class="mt-2 mb-4 text-ug-black">
                  $14.99
                  <span class="mr-2 text-sm">
                    / month
                  </span>
                  <br>
                  <a 
                    href="https://discord.gg/2qzn4P7qN2" 
                    class="bg-ug-green text-white active:bg-ug-gold font-bold px-2 py-2 rounded outline-none focus:outline-none uppercase text-lg shadow hover:shadow-lg ease-linear transition-all duration-150" 
                    target="_blank"
                  >
                    Get Started
                  </a>
                </p>
                <span class="mr-2 text-base">
                  32/32 Features
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Discord Webhook
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Moderation2 Integration
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  RocketMod, OpenMod, and Vanilla
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Logging
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Built-in approval for Admin staff
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Server micro-lags detection
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Whitelist
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Discord Role
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Discord Private Channel Access
                </span>
              </div>
            </div>
          </div>

          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
            >
              <div class="px-4 py-5 flex-auto bg-ug-d3grey shadow-lg rounded-lg">
  
                <h6 class="text-xl font-semibold">
                  <span class="text-ug-green">Gold</span> <span class="text-ug-black">Plan</span>
                </h6>
                <p class="mt-2 mb-4 text-ug-black">
                  $34.99
                  <span class="mr-2 text-sm">
                    / 3 month
                  </span>
                  <span class="line-through mr-2 text-sm text-ug-black">
                    $44.97
                  </span>
                  <br>
                  <a 
                    href="https://discord.gg/2qzn4P7qN2" 
                    class="bg-ug-green text-white active:bg-ug-gold font-bold px-2 py-2 rounded outline-none focus:outline-none uppercase text-lg shadow hover:shadow-lg ease-linear transition-all duration-150" 
                    target="_blank"
                  >
                    Get Started
                  </a>
                </p>
                <span class="mr-2 text-base">
                  32/32 Features
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Discord Webhook
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Moderation2 Integration
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  RocketMod, OpenMod, and Vanilla
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Logging
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Built-in approval for Admin staff
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Server micro-lags detection
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Whitelist
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Discord Role
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Discord Private Channel Access
                </span>
              </div>
            </div>
          </div>

          <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
            <div
              class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
            >
              <div class="px-4 py-5 flex-auto bg-ug-d3grey shadow-lg rounded-lg">
  
                <h6 class="text-xl font-semibold">
                  <span class="text-ug-green">Gold</span> <span class="text-ug-black">Plan</span>
                </h6>
                <p class="mt-2 mb-4 text-ug-black">
                  $119.99
                  <span class="mr-2 text-sm">
                    / 1 year
                  </span>
                  <span class="line-through mr-2 text-sm text-ug-black">
                    $179.88
                  </span>
                  <br>
                  <a 
                    href="https://discord.gg/2qzn4P7qN2" 
                    class="bg-ug-green text-white active:bg-ug-gold font-bold px-2 py-2 rounded outline-none focus:outline-none uppercase text-lg shadow hover:shadow-lg ease-linear transition-all duration-150" 
                    target="_blank"
                  >
                    Get Started
                  </a>
                </p>
                <span class="mr-2 text-base">
                  32/32 Features
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Discord Webhook
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Moderation2 Integration
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  RocketMod, OpenMod, and Vanilla
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Logging
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Built-in approval for Admin staff
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Server micro-lags detection
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Whitelist
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Discord Role
                </span>
                <div class="h-0 mx-2 my-2 border border-solid border-ug-gray"></div>
                <span class="mr-2 text-base">
                  Discord Private Channel Access
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class=" bg-ug-d1grey pb-48">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap justify-center text-center mb-8">
          <div class="w-full lg:w-9/12 px-4">
            <h2 class="text-4xl font-semibold">Features</h2>
            <p class="text-lg leading-relaxed m-4 text-blueGray-500 text-left">
              Are you tired of dealing with cheaters ruining your gaming experience? Our powerful anti-cheat software includes the most popular behavior detection, so you can ensure your server remains safe and secure.
            </p>
          </div>
        </div>
        <Features />
      </div>
    </section>

    <section class="pb-20 bg-ug-d1grey">
      <div class="container mx-auto px-4">
        <div class="flex flex-wrap justify-center text-center mb-8">
          <div class="w-full lg:w-9/12 px-4">
            <h2 class="text-4xl font-semibold">Meet the Team</h2>
            <p class="text-lg leading-relaxed m-4 text-blueGray-500 text-left">
              Have you ever been interested in who is working on UnturnedGuard? Currently, we have 6 team members in total.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap justify-center">
          {#each teamMembers as member}
            <div class="w-full sm:w-6/12 md:w-4/12 lg:w-3/12 px-4 mb-8">
              <div class="px-4 py-5 flex-auto bg-ug-d3grey shadow-lg rounded-lg text-center">
                <img alt={member.nickname} src={member.logo} class="w-24 h-24 mx-auto mt-4 rounded-full shadow-md">
                  <h6 class="text-xl font-semibold text-ug-green">{member.nickname}</h6>
                  <p class="mt-2 mb-4 text-ug-black">{member.description}</p>
                  <a 
                    href={member.link} 
                    class="text-ug-green hover:text-ug-gold transition-all duration-150" 
                    target="_blank"
                  >
                    <i class="fab fa-github fa-2x"></i>
                  </a>
                </div>
            </div>
          {/each}
        </div>
      </div>
    </section>

  </main>
  <Footer />
</div>
